import { StateMachineProvider } from 'little-state-machine'
import '../styles/globals.scss'
import NextNProgress from 'nextjs-progressbar';

function MyApp({ Component, pageProps }) {
  return (
    <>
    <NextNProgress />
    <StateMachineProvider>
      <Component {...pageProps} />
    </StateMachineProvider>
    </>)
}

export default MyApp
